<template>
  <div id="cat-cont">
    <b-row>
      <b-col>
        <b-overlay
          no-center
          :show="contentSelected.length > 0"
          variant="transparent"
          rounded="sm"
        >
          <template #overlay>
            <b-icon
              icon="stopwatch"
              variant="info"
              scale="2"
              shift-v="8"
              shift-h="8"
              class="position-absolute"
              style="top: 0; right: 0"
            />
          </template>
          <b-card>
            <b-card-header class="head">
              <div>
                <b-card-title>{{ $t('Categorias') }}</b-card-title>
                <h6 class="text-primary ">
                  {{ $t('campCat') }}
                </h6>
              </div>
              <h3>
                <b-badge
                  v-if="requiredField"
                  variant="danger"
                >
                  <feather-icon
                    icon="AlertOctagonIcon"
                    size="50"
                    style="vertical-align: baseline;"
                  />
                  {{ $t('needAdd') }}
                </b-badge>
              </h3>
            </b-card-header>
            <b-card-body>
              <b-form @submit.prevent>
                <b-row class="mb-2 mt-2">
                  <b-col md="6">
                    <b-row>
                      <b-col
                        md="5"
                        class="d-flex align-items-center"
                      >
                        <h6 class="text-primary font-weight-bold mb-0 ml-2 ">
                          {{ $t("code.allC") }}
                        </h6>
                        <feather-icon
                          v-show="resCategories.length > 0"
                          icon="PlusCircleIcon"
                          size="20"
                          class="text-success cursor-pointer ml-1"
                          @click="AddCat()"
                        />
                      </b-col>
                      <b-col md="5">
                        <b-form-input
                          v-model="searchCategories"
                          :placeholder="$t('slider.searchThreeCaracCat')"
                          @input="getCategoriesThreeCharact"
                        />
                      </b-col>
                    </b-row>

                    <b-form-select
                      v-model="checkResults"
                      tag="ul"
                      multiple
                      :select-size="5"
                      class="scroll-list list-group list-group-flush  mt-2 border-0"
                    >
                      <b-form-select-option
                        v-for="c in resCategories"
                        :key="c.node.id"
                        tag="li"
                        :value="c"
                        class="list-group-item"
                      >
                        <div class="d-flex ">
                          <div class="ml-25 ">
                            <b-card-text class="mb-0 font-weight-bold">
                              {{ c.node.name }}
                            </b-card-text>
                          </div>
                        </div>
                      </b-form-select-option>
                      <b-form-select-option
                        v-if="resCategories.length == 0"
                        :value="null"
                        disabled
                        class="list-group-item"
                      >
                        <div class="d-flex ">
                          <div
                            v-if="searchCategories.length <= 2"
                            class="ml-25 "
                          >
                            <b-card-text class="mb-0 font-weight-bold">
                              {{ threeCharSearch }}
                            </b-card-text>
                          </div>
                          <div
                            v-if="searchCategories.length >= 3"
                            class="text-left mt-2 ml-2"
                          >
                            <b-card-text class="mb-0 font-weight-bold">
                              {{ $t('subscriptions.notFound') }}
                            </b-card-text>
                          </div>
                        </div>
                      </b-form-select-option>
                    </b-form-select>
                  </b-col>
                  <!--categorías restringidas -->
                  <b-col md="6">
                    <b-row>
                      <b-col
                        md="4"
                        class="d-flex align-items-center"
                      >
                        <h6 class="text-primary font-weight-bold mb-0 mt-0">
                          {{ $t('subscriptions.selectedCategory') }}
                        </h6>
                        <feather-icon
                          v-show="resultCategorySelected.length > 0"
                          icon="XCircleIcon"
                          size="20"
                          class="text-danger cursor-pointer ml-1"
                          @click="QuitarCat()"
                        />
                      </b-col>
                      <b-col md="5">
                        <b-form-input
                          v-model="searchCatSelected"
                          :placeholder="$t('slider.searchThreeCaracCat')"
                          @input="getCategoriesSelected"
                        />
                      </b-col>
                    </b-row>

                    <b-form-select
                      v-model="checkRemove"
                      tag="ul"
                      multiple
                      :select-size="5"
                      class="scroll-list list-group list-group-flush mt-2 border-0"
                    >
                      <b-form-select-option
                        v-for="catSus in resultCategorySelected"
                        :key="catSus.node.id"
                        tag="li"
                        :value="catSus"
                        class="list-group-item"
                      >
                        <div class="d-flex ">
                          <div class="ml-25 ">
                            <b-card-text class="mb-0 font-weight-bold">
                              {{ catSus.node.name }}
                            </b-card-text>
                          </div>
                        </div>
                      </b-form-select-option>
                    </b-form-select>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <b-overlay
            no-center
            :show="categorySelected.length > 0"
            variant="transparent"
            rounded="sm"
          >
            <template #overlay>
              <b-icon
                icon="stopwatch"
                variant="info"
                scale="2"
                shift-v="8"
                shift-h="8"
                class="position-absolute"
                style="top: 0; right: 0"
              />
            </template>
            <b-card-header class="head">
              <b-card-title>{{ $t('Contents') }}</b-card-title>
              <h6 class="text-primary ">
                {{ $t('contents.contCampg') }}
              </h6>
            </b-card-header>
            <b-card-body>
              <b-form @submit.prevent>
                <b-row class="m-2">
                  <!-- people group 1 -->
                  <b-col md="6">
                    <b-row>
                      <b-col md="5">
                        <h6 class="text-primary font-weight-bold mb-2">
                          {{ $t("code.allCon") }}
                          <feather-icon
                            v-show="resContents.length > 0"
                            icon="PlusCircleIcon"
                            size="20"
                            class="text-success cursor-pointer ml-1"
                            @click="AddCont()"
                          />
                        </h6>
                      </b-col>
                      <b-col md="5">
                        <b-form-input
                          v-model="searchContents"
                          minlength="3"
                          :placeholder="$t('slider.searchThreeCaracContent')"
                          @input="getContentsThreeCharact"
                        />
                      </b-col>
                    </b-row>

                    <b-form-select
                      v-model="checkContResults"
                      tag="ul"
                      multiple
                      :select-size="5"
                      class="scroll-list list-group list-group-flush  mt-2 border-0"
                    >
                      <b-form-select-option
                        v-for="c in resContents"
                        :key="c.node.id"
                        tag="li"
                        :value="c"
                        class="list-group-item"
                      >
                        <div class="d-flex ">
                          <div class="ml-25 ">
                            <b-card-text class="mb-0 font-weight-bold">
                              {{ c.node.name }}
                            </b-card-text>
                          </div>
                        </div>
                      </b-form-select-option>
                      <b-form-select-option
                        v-if="resContents.length == 0"
                        :value="null"
                        disabled
                        class="list-group-item"
                      >
                        <div class="d-flex ">
                          <div
                            v-if="searchContents.length <= 2"
                            class="ml-25 "
                          >
                            <b-card-text class="mb-0 font-weight-bold">
                              {{ threeCharSearch }}
                            </b-card-text>
                          </div>
                          <div
                            v-if="searchContents.length >= 3"
                            class="text-left mt-2 ml-2"
                          >
                            <b-card-text class="mb-0 font-weight-bold">
                              {{ $t('subscriptions.notFound') }}
                            </b-card-text>
                          </div>
                        </div>
                      </b-form-select-option>
                    </b-form-select>
                  </b-col>
                  <!-- people group 2 -->
                  <b-col md="6">
                    <b-row>
                      <b-col
                        md="5"
                        class="d-flex align-items-center"
                      >
                        <h6 class="text-primary font-weight-bold mb-0 mt-0">
                          {{ $t('selectCont') }}
                        </h6>
                        <feather-icon
                          v-show="resultContentsSelected.length > 0"
                          icon="XCircleIcon"
                          size="20"
                          class="text-danger cursor-pointer ml-1"
                          @click="QuitarCont()"
                        />
                      </b-col>
                      <b-col md="5">
                        <b-form-input
                          v-model="searchContSelected"
                          :placeholder="$t('slider.searchThreeCaracContent')"
                          @input="getContentsSelected"
                        />
                      </b-col>
                    </b-row>
                    <b-form-select
                      v-model="checkContRemove"
                      tag="ul"
                      multiple
                      :select-size="5"
                      class="scroll-list list-group list-group-flush mt-2 border-0"
                    >
                      <b-form-select-option
                        v-for="cont in resultContentsSelected"
                        :key="cont.node.id"
                        tag="li"
                        :value="cont"
                        class="list-group-item"
                      >
                        <div class="d-flex ">
                          <div class="ml-25 ">
                            <b-card-text class="mb-0 font-weight-bold">
                              {{ cont.node.name }}
                            </b-card-text>
                          </div>
                        </div>
                      </b-form-select-option>
                    </b-form-select>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-overlay>
          <b-button
            class="m-1"
            variant="success"
            @click="update"
          >
            {{
              $t("dataGeneric.save")
            }}
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import * as constants from '@core/utils/constants'

import {
  BButton,
  BIcon,
  BFormSelect,
  BFormSelectOption,
  BRow, BCol, BBadge, BCard, BOverlay, BForm, BCardBody, BCardTitle, BCardHeader, BCardText, BFormInput,
} from 'bootstrap-vue'
import axios from '@axios'
import { messageError, showToast, utf8ToB64 } from '@/store/functions'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BButton,
    BBadge,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormSelect,
    BFormSelectOption,
    BCardBody,
    BCardTitle,
    BIcon,
    BCardHeader,
    BCardText,
    BOverlay,
    BForm,
  },
  props: {
    info: {
      default: [],
    },
  },
  data() {
    return {
      isSponsor: false,
      userData: getUserData(),
      categorySelected: [],
      contentSelected: [],
      allCats: [],
      allConts: [],
      checkResults: [],
      checkRemove: [],
      checkContResults: [],
      checkContRemove: [],
      resultContentsSelected: [],
      resultCategorySelected: [],
      resCategories: [],
      resContents: [],
      threeCharSearch: this.$t('slider.searchThreeCaracDefault'),
      showContents: false,
      showCategories: false,
      searchCategories: '',
      searchContents: '',
      searchContSelected: '',
      searchCatSelected: '',
      requiredField: false,
    }
  },
  watch: {
    requiredField() {
      if (!this.sponsor) {
        this.requiredField = false
      }
    },
    resCategories() {
      if (this.resCategories.length !== 0) {
        if (this.searchCategories.length <= 2) {
          this.resCategories = []
          this.threeCharSearch = this.$t('slider.searchThreeCaracCat')
        }
      }
    },
    resContents() {
      if (this.resContents.length !== 0) {
        if (this.searchContents.length <= 2) {
          this.resContents = []
          this.threeCharSearch = this.$t('slider.searchThreeCaracContent')
        }
      }
    },
    searchContents(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (this.searchContents.length <= 2) {
          this.resContents = []
          this.threeCharSearch = this.$t('slider.searchThreeCaracContent')
        }
      }
    },
    searchCategories(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (this.searchCategories.length <= 2) {
          this.resCategories = []
          this.threeCharSearch = this.$t('slider.searchThreeCaracCat')
        }
      }
    },
    categorySelected() {
      if (this.categorySelected.length === 0 && this.contentSelected.length === 0) {
        this.requiredField = true
      } else {
        this.requiredField = false
      }
    },
    contentSelected() {
      if (this.categorySelected.length === 0 && this.contentSelected.length === 0) {
        this.requiredField = true
      } else {
        this.requiredField = false
      }
    },
    info() {
      this.categorySelected = this.info.categories.edges
      this.contentSelected = this.info.contents.edges
      if (this.contentSelected.length === 0) this.contentSelected = []
      if (this.categorySelected.length === 0) this.categorySelected = []
      this.getCategoriesSelected()
      this.getContentsSelected()
    },
  },
  mounted() {
    if (this.userData.groups.edges.length !== 0) {
      this.isSponsor = this.userData.groups.edges[0].node.name === constants.sponsor
    }
  },
  methods: {
    update() {
      if (this.isSponsor && this.contentSelected.length === 0 && this.categorySelected.length === 0) {
        showToast(this.$t('isRequired'), 0, this)
      } else {
        this.updateContents()
        this.updateCategories()
      }
    },
    updateResContents() {
      this.contentSelected.forEach(element => {
        this.allConts = this.allConts.filter(e => element.node.id !== e.node.id)
      })
    },
    updateContentSelected() {
      const difference = this.resContents.filter(x => !this.allConts.includes(x))

      this.allConts = [...difference, ...this.allConts]
    },
    getContents() {
      axios
        .post('', {
          query: `
           query{
            contentsUnlimited(client:"${this.userData.profile.client.id}",name:"${this.searchContents}"
            ${this.isSponsor ? `,creator:"${utf8ToB64(`id:${this.userData.id}`)}"` : ''}  
            ){
                edges {
                    node {
                    id
                    name                    
                    }
                }
            }                
        }   
        `,
        })
        .then(response => {
          messageError(response, this)

          const arrayTotal = response.data.data.contentsUnlimited.edges
          this.contents = response.data.data.contentsUnlimited.edges
          const arrayselec = []

          for (let i = 0; i < arrayTotal.length; i += 1) {
            let bool = false
            for (let j = 0; j < this.contentSelected.length; j += 1) {
              if (this.contentSelected[j].node.id === arrayTotal[i].node.id) {
                bool = true
              }
            }

            if (!bool) {
              arrayselec.push(arrayTotal[i])
            }
          }
          this.resContents = arrayselec.slice()
          this.allConts = arrayselec.slice()
        })
    },
    getCategories() {
      axios
        .post('', {
          query: `
           query{
            categoriesUnlimited(client:"${this.userData.profile.client.id}",name:"${this.searchCategories}"
            ${this.isSponsor ? `,filter_Sponsor:"${this.userData.id}"` : ''}  
            ){
                edges {
                    node {
                    id
                    name                    
                    }
                }
            }                
        }   
        `,
        })
        .then(response => {
          messageError(response, this)

          const arr2 = response.data.data.categoriesUnlimited.edges
          const array = []
          for (let i = 0; i < arr2.length; i += 1) {
            let bool = false
            for (let j = 0; j < this.categorySelected.length; j += 1) {
              if (this.categorySelected[j].node.id === arr2[i].node.id) {
                bool = true
              }
            }

            if (!bool) {
              array.push(arr2[i])
            }
          }
          this.categories = arr2.slice()

          this.resCategories = array.slice()
          this.allCats = array.slice()

          this.searchByName()
        })
    },
    getIdArray(array) {
      const res = []
      for (let i = 0; i < array.length; i += 1) {
        res.push(array[i].node.id)
      }
      return res
    },
    updateCategories() {
      const { id } = this.$route.params

      axios
        .post('', {
          query: `
          mutation{
            updateCampaign(id:"${id}",input:{
            categories: [],              
            }){
              campaing
              {                                 
                name
              }
            }
          }
        `,
        })
        .then(result => {
          messageError(result, this)

          this.updateArrayData(this.getIdArray(this.categorySelected), 0, this)
          showToast(this.$t('code.updateData'), 1, this)
        })
        .catch(err => {
          console.log(err)
          showToast(this.$t('code.updateDataError'), 0, this)
        })
    },
    updateContents() {
      const { id } = this.$route.params

      axios
        .post('', {
          query: `
          mutation{
            updateCampaign(id:"${id}",input:{
              contents: [],     
            }){
              campaing
              {                      
                name
                client{
                  id
                  name
                }
                
              }
            }
          }
        `,
        })
        .then(result => {
          messageError(result, this)

          this.updateArrayData(this.getIdArray(this.contentSelected), 1)

          showToast(this.$t('code.updateData'), 1, this)
        })
        .catch(() => {
          showToast(this.$t('code.updateDataError'), 0, this)
        })
    },
    updateArrayData(array, type) {
      const { id } = this.$route.params
      axios
        .post('', {
          query: `
          mutation{
            updateCampaign(id:"${id}",input:{
              ${type === 0 ? `categories: [${array}],` : `contents: [${array}],`}
            }){
              campaing
              {
                name
                categories{
                  edges{
                    node{
                      id
                      name
                    }
                  }
                }
                contents{
                  edges{
                    node{
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        `,
        })
        .then(result => {
          messageError(result, this)

          this.$emit('updateData')
        })
        .catch(err => {
          console.log(err)
        })
    },
    QuitarCont() {
      if (this.checkContRemove.length !== 0) {
        this.allConts = [...this.checkContRemove, ...this.allConts]
        this.checkContRemove.forEach(element => {
          const idx = this.contentSelected.indexOf(element)
          if (idx !== -1) {
            this.contentSelected.splice(idx, 1)
          }
        })
        this.checkContRemove = []
        this.searchContentsByName()
        this.getContentsSelected()
      } else {
        this.allConts = [...this.resultContentsSelected, ...this.allConts]

        this.resultContentsSelected.forEach(element => {
          const idx = this.contentSelected.indexOf(element)
          if (idx !== -1) {
            this.contentSelected.splice(idx, 1)
          }
        })
        this.resultContentsSelected = []
        this.searchContentsByName()
      }
    },
    QuitarCat() {
      if (this.checkRemove.length !== 0) {
        this.allCats = [...this.checkRemove, ...this.allCats]
        this.checkRemove.forEach(element => {
          const idx = this.categorySelected.indexOf(element)
          if (idx !== -1) {
            this.categorySelected.splice(idx, 1)
          }
        })
        this.checkRemove = []
        this.searchByName()
        this.getCategoriesSelected()
      } else {
        this.allCats = [...this.resultCategorySelected, ...this.allCats]

        this.resultCategorySelected.forEach(element => {
          const idx = this.categorySelected.indexOf(element)
          if (idx !== -1) {
            this.categorySelected.splice(idx, 1)
          }
        })
        this.resultCategorySelected = []
        this.searchByName()
      }
    },
    AddCat() {
      if (this.checkResults.length !== 0) {
        this.categorySelected = [...this.categorySelected, ...this.checkResults]
        this.getCategoriesSelected()
        this.checkResults.forEach(element => {
          const idx = this.resCategories.indexOf(element)
          if (idx !== -1) {
            this.resCategories.splice(idx, 1)
          }
          this.allCats = this.allCats.filter(e => element.node.id !== e.node.id)
        })
        this.checkResults = []
        this.searchByName()
      } else {
        this.categorySelected = [...this.categorySelected, ...this.resCategories]
        this.getCategoriesSelected()

        this.resCategories.forEach(element => {
          this.allCats = this.allCats.filter(e => element.node.id !== e.node.id)
        })

        this.resCategories = []

        this.searchByName()
      }
    },
    getCategoriesSelected() {
      this.resultCategorySelected = this.categorySelected.filter(item => item.node.name.toLowerCase().includes(this.searchCatSelected.toLowerCase()))
    },
    searchByName() {
      this.resCategories = this.allCats.filter(item => item.node.name.toLowerCase().includes(this.searchCategories.toLowerCase()))
      this.resCategories.sort((firstParams, secondParams) => firstParams.node.name.localeCompare(secondParams.node.name))
    },
    AddCont() {
      if (this.checkContResults.length !== 0) {
        this.contentSelected = [...this.contentSelected, ...this.checkContResults]
        this.getContentsSelected()
        this.checkContResults.forEach(element => {
          const idx = this.resContents.indexOf(element)
          if (idx !== -1) {
            this.resContents.splice(idx, 1)
          }
          this.allConts = this.allConts.filter(e => element.node.id !== e.node.id)
        })
        this.checkContResults = []
        this.searchContentsByName()
      } else {
        this.contentSelected = [...this.contentSelected, ...this.resContents]
        this.getContentsSelected()
        this.resContents.forEach(element => {
          this.allConts = this.allConts.filter(e => element.node.id !== e.node.id)
        })
        this.resContents = []
        this.searchContentsByName()
      }
    },
    getContentsSelected() {
      this.resultContentsSelected = this.contentSelected.filter(item => item.node.name.toLowerCase().includes(this.searchContSelected.toLowerCase()))
    },
    getCategoriesThreeCharact() {
      if (this.searchCategories.trim().length >= 3) {
        this.getCategories()
      }
    },
    getContentsThreeCharact() {
      if (this.searchContents.trim().length >= 3) {
        this.getContents()
      }
    },
    searchContentsByName() {
      this.resContents = this.allConts.filter(item => item.node.name.toLowerCase().includes(this.searchContents.toLowerCase()))
      this.resContents.sort((firstParams, secondParams) => firstParams.node.name.localeCompare(secondParams.node.name))
    },
  },
}
</script>
<style lang="scss" scoped>
#cat-cont .scroll-list {
  overflow: hidden;
  overflow-y: scroll;
  height: 200px;
  // border: 1px solid;
  margin: 2px;
}

#cat-cont .head {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>
